import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
} from "@angular/core";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { TranslationService } from "./modules/i18n/translation.service";
// language list
import { locale as enLang } from "./modules/i18n/vocabs/en";
import { locale as chLang } from "./modules/i18n/vocabs/ch";
import { locale as esLang } from "./modules/i18n/vocabs/es";
import { locale as jpLang } from "./modules/i18n/vocabs/jp";
import { locale as deLang } from "./modules/i18n/vocabs/de";
import { locale as frLang } from "./modules/i18n/vocabs/fr";
import { SplashScreenService } from "./_metronic/partials/layout/splash-screen/splash-screen.service";
import { MAT_DATE_FORMATS } from "@angular/material/core";
// import { TableExtendedService } from './_metronic/shared/crud-table';

@Component({
  // tslint:disable-next-line:component-selector
  selector: "body[root]",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  constructor(
    private translationService: TranslationService,
    private splashScreenService: SplashScreenService,
    private router: Router, // private tableService: TableExtendedService
    private changeDetectorRef: ChangeDetectorRef
  ) {
    // register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );
  }
  openWpp = false;

  toggle() {
    this.openWpp = !this.openWpp;
    this.changeDetectorRef.detectChanges();
  }

  wpp_web =
    "https://web.whatsapp.com/send?phone=551195046-5213&text=Olá, tenho dúvidas sobre como fazer o testamento.";
  wpp_mobile =
    "https://api.whatsapp.com/send?phone=5511950465213&text=Ol%C3%A1%2C%20tenho%20d%C3%BAvidas%20sobre%20como%20fazer%20o%20testamento.";
  link_wpp = window.screen.width < 768 ? this.wpp_mobile : this.wpp_web;

  ngOnInit() {
    console.log('STAR APP -->>>')
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // clear filtration paginations and others
        // this.tableService.setDefaults();
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add("page-loaded");
        }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
