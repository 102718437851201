// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements 

export const environmentProd = {
  production: true,
  appVersion: '0.2',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: 'https://fale.api.bemlegal.com.br/api/x/rest',
  apiFamilyUrl: 'https://fale.api.bemlegal.com.br/api/family',
  apiAssetsUrl: 'https://fale.api.bemlegal.com.br/api/assets',
  GOOGLE_KEY: '838381896557-16ei1jcfjqauuagrotl57af0ditrpq49.apps.googleusercontent.com',
  FACEBOOK_KEY: '206795227654091',
  RDS_BASE_URL: "https://api.rd.services/",
  RDS_KEY: "bNVAQLhcObXXLCtoVvIXGDngDETdDOBaVvty",
  RDS_TOKEN: "b8f7327594bf1028d0eb2e0b898f23c3",
  VALUE_PAYMENT: 348
};

export const environmentDev = {
  production: false,
  appVersion: "0.2",
  USERDATA_KEY: "authf649fc9a5f533",
  isMockEnabled: true,
  apiUrl: "https://develop.api.bemlegal.com.br/api/x/rest",
  apiFamilyUrl: "https://develop.api.bemlegal.com.br/api/family",
  apiAssetsUrl: "https://develop.api.bemlegal.com.br/api/assets",
  GOOGLE_KEY:
    "838381896557-2606ckk0dqc6al56s6a9h5a2qr94ig86.apps.googleusercontent.com",
  FACEBOOK_KEY: "482124826091204",
  RDS_BASE_URL: "https://api.rd.services/",
  RDS_KEY: "bNVAQLhcObXXLCtoVvIXGDngDETdDOBaVvty",
  RDS_TOKEN: "b8f7327594bf1028d0eb2e0b898f23c3",
  VALUE_PAYMENT: 348,
};
export const environmentLocal = {
  production: false,
  appVersion: '0.2',
  USERDATA_KEY: 'authf649fc9a5f533',
  isMockEnabled: true,
  apiUrl: 'http://localhost:8991/rest',
  apiFamilyUrl: 'http://localhost:9031',
  apiAssetsUrl: 'http://localhost:9032',
  GOOGLE_KEY: '838381896557-2606ckk0dqc6al56s6a9h5a2qr94ig86.apps.googleusercontent.com',
  FACEBOOK_KEY: '482124826091204',
  RDS_BASE_URL: "https://api.rd.services/",
  RDS_KEY: "bNVAQLhcObXXLCtoVvIXGDngDETdDOBaVvty",
  RDS_TOKEN: "b8f7327594bf1028d0eb2e0b898f23c3",
  VALUE_PAYMENT: 348,
  Public_path: "../../../../../../public"
};

const discriminatorEnv = (env: string) => {
  switch (env) {
    case "DEV":
      return environmentDev
    case "LOCAL":
      return environmentLocal
    case "PROD":
      return environmentProd

    default:
      return environmentDev
  }
}

export const environment = discriminatorEnv("DEV")

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
